<template>
    <div id="app">
      <commonbanner></commonbanner>
      <div class="mt-4 mb-4">
        <div class="container expertadd">
    <a-form-model v-bind="formInfo" :model="expert" :rules="rules" ref="ruleForm">
      <div class="subtitle"><h2>公共法律服务志愿者申请</h2><hr class="underline"></div>
      <a-divider orientation="left">志愿者个人基本信息</a-divider>
      <a-row :gutter="70">
        <a-col :span="10" :offset="2">
          <a-form-model-item label="姓名" prop="expert_name">
            <a-input type="text" placeholder="请输入您的真实姓名" v-model="expert.expert_name"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="9">
          <a-form-model-item label="性别:" prop="expert_gender">
            <a-radio-group v-model="expert.expert_gender">
              <a-radio value="1"> 男 </a-radio>
              <a-radio value="0"> 女 </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="70">
        <a-col :span="10" :offset="2">
          <a-form-model-item label="民族:" prop="expert_nation">
            <a-select
              show-search
              allow-clear
              placeholder="民族"
              option-filter-prop="children"
              style="width: 310px"
              :filter-option="filterOption"
            >
              <a-select-option v-for="item in createdInfo.expert_nation" :key="item.item_value">
                {{item.item_name}}
              </a-select-option>
          </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="9">
          <a-form-model-item label="出生日期:" prop="expert_birthday">
            <a-date-picker placeholder="请选择您的生日" v-model="expert.expert_birthday"/>
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-row :gutter="70">
        <a-col :span="10" :offset="2">
          <a-form-model-item label="身份证" prop="id_card">
            <a-input type="text" placeholder="请输入18位身份证号码" v-model="expert.id_card"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="9">
          <a-form-model-item label="政治面貌:" prop="politics_status">
            <a-select placeholder="请选择政治面貌" :allowClear="true" v-model="expert.politics_status">
              <a-select-option v-for="item in createdInfo.politics_status" :key="item.item_value"> {{item.item_name}} </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="70">
        <a-col :span="10" :offset="2">
          <a-form-model-item label="身体状况:" prop="physical_condition">
            <a-select
              placeholder="请选择健康状况"
              default-value="hanzu"
              :allowClear="true"
              v-model="expert.physical_condition"
            >
              <a-select-option value="healthy"> 健康 </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="9">
          <a-form-model-item label="联系电话:" prop="contact_number">
            <a-input type="text" placeholder="请输入11位联系电话" v-model="expert.contact_number"/>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="70">
        <a-col :span="10" :offset="2">
          <a-form-model-item label="电子邮箱:" prop="email_address">
            <a-input type="text" v-model="expert.email_address"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="9">
          <a-form-model-item label="通讯地址:" prop="correspondence_address">
            <a-input type="text" v-model="expert.correspondence_address"/>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="70">
        <a-col :span="10" :offset="2">
          <a-form-model-item label="职业:" prop="position">
            <a-input type="text" placeholder="请输入当前所从事的职业" v-model="expert.position"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="9">
          <a-form-model-item label="专业:" prop="profession">
            <a-input type="text" placeholder="请输入专业" v-model="expert.profession"/>
          </a-form-model-item>
        </a-col>
         </a-row
      ><a-row :gutter="70">
        <a-col :span="10" :offset="2">
          <a-form-model-item label=" 最高学历:" prop="highest_degree">
            <a-select placeholder="请选择最高学历" :allowClear="true" v-model="expert.highest_degree">
                <a-select-option v-for="item in createdInfo.highest_degree" :key="item.item_value" :value="item.item_value"> {{item.item_name}} </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="9">
          <a-form-model-item label="最高学位:" prop="highest_diploma">
            <a-select placeholder="请选择所取得最高学位" :allowClear="true" v-model="expert.highest_diploma">
              <a-select-option v-for="item in createdInfo.highest_diploma" :key="item.item_value" :value="item.item_value"> {{item.item_name}} </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-row :gutter="70">
        <a-col :span="10" :offset="2">
          <a-form-model-item label="毕业院校:" prop="graduate_school">
            <a-input type="text" placeholder="请输入最高学历所就读院校" v-model="expert.graduate_school"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="9">
          <a-form-model-item label="所在单位:" prop="org_name">
            <a-input type="text" v-model="expert.org_name"/>
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-row :gutter="70">
        <a-col :span="10" :offset="2">
          <a-form-model-item label="单位联系电话:" prop="org_contact_number">
            <a-input type="text" v-model="expert.org_contact_number"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="9">
          <a-form-model-item label="工作经历:" prop="work_experience">
            <a-textarea placeholder="请输入工作经历" :rows="4" v-model="expert.work_experience"/>
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-divider orientation="left">附件上传</a-divider>

      <a-row :gutter="60">
        <a-col :span="4" :offset="5">
          <upLoadPic :actionUrl="picUpload.p1actionUrl"></upLoadPic>
        </a-col>
      </a-row>
      <a-divider orientation="left">提交</a-divider>
      <a-row :gutter="70">
<!--        <a-col :span="10" :offset="5">-->
<!--          <a-switch @click="confirmAggrement()">  </a-switch>-->
<!--          <a-->
<!--            href="javascript:void(0)"-->
<!--            @click="showAgreement()"-->
<!--            style="color: #004996"-->
<!--            >《XX政务服务网注册协议》</a-->
<!--          >-->
<!--        </a-col>-->
        <a-col>
          <a-col>
            <a-form-model-item :wrapper-col="{ span: 5, offset: 11 }">
              <a-button type="primary" @click="onSubmit"> 提交 </a-button>
            </a-form-model-item>
          </a-col>
        </a-col>
      </a-row>
    </a-form-model>
    <!-- <a-modal
      v-model="formInfo.any"
      title="XX省公共法律服务网注册协议"
      okText="确认"
      cancelText="关闭"
      @ok="handleOk"

    >
      <p>Some contents...</p>
      <p>Some contents...</p>
      <p>Some contents...</p>
    </a-modal> -->
  </div>
      </div>
    </div>
</template>
<script>
import UpLoadPic from '@/components/business/UpLoadPic'
import Commonbanner from '@/components/base/Commonbanner'
import model from '../../core/model.js'
export default {
  name: 'expertApply',
  mixins: [model],
  components: {
    Commonbanner,
    UpLoadPic
  },
  mounted () {
    this.initSelect()
  },
  data () {
    return {
      formInfo: {
        layout: 'horizontal',
        labelCol: { span: 6, offset: 0 },
        wrapperCol: { span: 18, offset: 0 },
        name: '',
        confirmAggrement: false,
        any: false
      },
      expert: {
        // 是否推送
        istop: false,
        // 身份证
        id_card: '',
        // 姓名
        expert_name: '',
        // 性别
        expert_gender: '1',
        // 民族
        expert_nation: '1',
        // 政治面貌
        politics_status: '',
        // 生日
        expert_birthday: '',
        // 职业
        position: '',
        // 职称
        job_title: '',
        // 身体状况
        physical_condition: '',
        // 最高学历
        highest_degree: '',
        // 专业
        profession: '',
        // 最高学位
        highest_diploma: '',
        // 研究专长
        specialty: '',
        // 资格证书
        qualification_certificate: '',
        // 毕业院校
        graduate_school: '',
        // 资格证书编号
        qualification_certificate_no: '',
        // 执业证书编号
        practicing_certificate_no: '',
        // 所在律所或单位
        org_name: '',
        // 通讯地址
        correspondence_address: '',
        // 联系电话
        contact_number: '',
        // 电子邮箱
        email_address: '',
        // 学习简历
        study_resume: '',
        // 单位联系电话
        org_contact_number: '',
        // 工作经历
        work_experience: '',
        // 组织证明
        org_prove: '(binary)',
        // 重大成就
        tremendous_achievements: '',
        // 领域分类
        business_area: '',
        // 人才分类
        talents_area: '',
        // 单位意见
        org_pinion: '',
        // 本人意见
        self_opinion: '',
        // 权重
        weight: '0'
      },
      picUpload: {
        p1actionUrl: '',
        p2actionUrl: '',
        p3actionUrl: '',
        p4actionUrl: ''
      },
      createdInfo: {
        expert_nation: [],
        politics_status: [],
        physical_condition: [],
        highest_degree: [],
        highest_diploma: [],
        business_area: [],
        talents_area: []
      },
      rules: {
        expert_name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在2到5个中文之间', trigger: 'blur' }
        ],
        expert_gender: [
          { required: true, message: '请选择性别', trigger: 'blur' }
        ],
        id_card: [
          { required: true, message: '请输入身份证号码', trigger: 'blur' },
          { len: 18, message: '格式不正确 请检查', trigger: 'blur' }
        ],
        expert_nation: [
          { required: true, message: '请选择民族', trigger: 'blur' }
        ],
        expert_birthday: [
          { required: true, message: '请选择出生日期', trigger: 'blur' }
        ],
        politics_status: [
          { required: true, message: '请选择政治面貌', trigger: 'blur' }
        ],
        physical_condition: [
          { required: true, message: '请选择健康状况', trigger: 'blur' }
        ],
        contact_number: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          { validator: this.verificationPhone, trigger: 'blur' }
        ],
        position: [
          { required: true, message: '请输入职业', trigger: 'blur' }
        ],
        job_title: [
          { required: true, message: '请输入职称', trigger: 'blur' }
        ],
        profession: [
          { required: true, message: '请输入专业', trigger: 'blur' }
        ],
        highest_degree: [
          { required: true, message: '请选择最高学历', trigger: 'blur' }
        ],
        highest_diploma: [
          { required: true, message: '请选择最高学位', trigger: 'blur' }
        ],
        specialty: [
          { required: true, message: '请输入研究专长', trigger: 'blur' }
        ],
        qualification_certificate: [
          { required: true, message: '请输入资格证书', trigger: 'blur' }
        ],
        qualification_certificate_no: [
          { required: true, message: '请输入资格证书编号', trigger: 'blur' }
        ],
        graduate_school: [
          { required: true, message: '请输入毕业院校', trigger: 'blur' }
        ],
        work_experience: [
          { required: true, message: '请输入工作经历', trigger: 'blur' }
        ],
        org_name: [
          { required: true, message: '请输入所属律所或单位', trigger: 'blur' }
        ],
        correspondence_address: [
          { required: true, message: '请输入通讯地址', trigger: 'blur' }
        ],
        org_contact_number: [
          { required: true, message: '请输入单位联系电话', trigger: 'blur' }
        ],
        email_address: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: this.verificationEmail, trigger: 'blur' }
        ],
        public_provider_class: [
          { required: true, message: '公法领域类型', trigger: 'blur' }
        ],
        government_provider_class: [
          { required: true, message: '政府法律领域类型', trigger: 'blur' }
        ],
        business_area: [
          { required: true, message: '业务领域类型', trigger: 'blur' }
        ],
        practicing_certificate_no: [
          { required: true, message: '执业证书编号不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    showAgreement () {
      this.formInfo.any = true
    },
    handleOk (e) {
      this.formInfo.any = false
    },
    initSelect () {
      this.$api.get('/api/politics_status').then((res) => {
        this.createdInfo.politics_status = res.data.collection
      })
      this.$api.get('/api/education').then((res) => {
        this.createdInfo.highest_degree = res.data.collection
      })
      this.$api.get('/api/diploma').then((res) => {
        this.createdInfo.highest_diploma = res.data.collection
      })
      this.$api.get('/api/volunteer').then((res) => {
        console.log(res.data.collection)
      })
      this.$api.get('/api/nation').then((res) => {
        this.createdInfo.expert_nation = res.data.collection
      })
    },
    confirmAggrement () {
      this.formInfo.confirmAggrement = !this.formInfo.confirmAggrement
    },
    verificationEmail (rule, value, callback) {
      const passwordReg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if (passwordReg.test(value)) {
        callback()
      } else {
        callback(new Error('格式输入错误'))
      }
    },
    verificationPhone (rule, value, callback) {
      const passwordReg = /^1\d{10}$/
      if (passwordReg.test(value)) {
        callback()
      } else {
        callback(new Error('格式输入错误'))
      }
    },
    onSubmit () {
      var that = this
      var res = that.saveData({ url: 'volunteer', data: this.expert })
      if (res) {
        console.log(res)
        this.$message.info('提交成功')
        this.$router.push('/torf')
      } else {
        this.$message.info('提交失败')
      }
      // this.$refs.ruleForm.validate((valid) => {
      //   if (valid) {
      //     if (!this.formInfo.confirmAggrement) {
      //       this.$message.info('请同意用户协议')
      //       return false
      //     }
      //     this.$api.post('/api/volunteer', this.expert).then((res) => {
      //       console.log('提交成功')
      //       this.$message.info('提交成功')
      //     })
      //   } else {
      //     console.log('error submit!!')
      //     return false
      //   }
      // })
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
</script>
<style scoped src="../../assets/css/ez_apply.css"></style>

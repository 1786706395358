import qs from 'qs'
export default {
  methods: {
    async saveData (payload) {
      const { url, method = 'post', data: body = null, key, query, options } = payload
      this.commiting = true
      try {
        const { data: { data } } = await this.$api[method.toLowerCase()](
          `/api/${url}${key ? `/${key}` : ''}?${qs.stringify(query)}`,
          body,
          options
        )
        return data
      } catch (error) {
        return this.handleError(error)
      } finally {
        this.commiting = false
      }
    },
    async executeData (payload) {
      const { method = 'post' } = payload
      return await this.saveData({ type: 'execute', method, ...payload })
    },
    handleError (error) {
      if (!error.response) {
        return '网络请求错误，请检查您的网络连接。'
      }
      const { status } = error.response
      if (status === 401) {
        // 未登录或登录失效
      } else if (status === 403) {
        // 权限不足
      } else if (status === 404) {
        // 数据不存在
      } else {
        // 服务器错误
      }
      return error
    }
  }
}

import { render, staticRenderFns } from "./VolunteerApply.vue?vue&type=template&id=89335e02&scoped=true&"
import script from "./VolunteerApply.vue?vue&type=script&lang=js&"
export * from "./VolunteerApply.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/ez_apply.css?vue&type=style&index=0&id=89335e02&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89335e02",
  null
  
)

export default component.exports